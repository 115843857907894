import { action } from "easy-peasy";

const dictionaryStore = {
  payload: null,
  setDictionary: action((state, payload) => {
    state.payload = payload;
  }),
};

export default dictionaryStore;
