import "../styles/globals.css";

import { Poppins } from "next/font/google";

import { useEffect, useState } from "react";
import { StoreProvider } from "easy-peasy";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "theme";

import store from "store";

import dynamic from "next/dynamic";
import Script from "next/script";

const LoadingComponent = dynamic(() => import("components/Loading"));

const poppins = Poppins({
  subsets: ["latin-ext"],
  weight: ["400", "500", "600"],
  preload: false,
});

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);
  }, [router]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.add(poppins.className);
    }
  }, []);

  return (
    <>
      <main className={poppins.className}>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            {loading && <LoadingComponent />}
            <Component {...pageProps} />
          </ThemeProvider>
        </StoreProvider>
      </main>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TXPPS8SB');`,
        }}
      />
    </>
  );
};

export default appWithTranslation(MyApp);
