import { createStore, persist } from "easy-peasy";

import dictionaryStore from "./dictionary";
import favoritesStore from "./favorites";

const store = createStore(
  {
    // auth: persist(authStore, {
    //   deny: ["firstLogin"],
    //   storage: "localStorage",
    // }),
    // modals: persist(modalsStore, {
    //   deny: ["loginModal"],
    // }),
    // favorite: persist(favoriteStore),
    // appInfo: persist(appInfoStore),
    // errorsStore: persist(errorsStore, {
    //   deny: ["errorApi"],
    // }),
    dictionary: persist(dictionaryStore),
    favorites: persist(favoritesStore, { storage: "localStorage" }),
  },
  { devTools: process.env.NODE_ENV === "development" }
);

export default store;

export const actions = store.getActions();
