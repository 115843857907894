import { action, thunk } from "easy-peasy";

const favorites = {
  favorites: [],
  addFavorite: action((state, payload) => {
    state.favorites.push(payload);
  }),
  removeFavorite: action((state, payload) => {
    state.favorites = state.favorites.filter((item) => item !== payload);
  }),
};

export default favorites;
