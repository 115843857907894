import { forwardRef } from "react";
import NextLink from "next/link";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} passHref {...props} />;
});

const theme = createTheme({
  typography: {
    fontFamily: "inherit",

    htmlFontSize: 16,
    h1: {
      fontSize: "3.5em",
      lineHeight: 1,
      fontWeight: "bold",
      color: "black",
    },
    h2: {
      fontSize: "2em",
      lineHeight: 1.56,
      fontWeight: "bold",
      color: "black",
    },
    h3: {
      fontSize: "1.625em",
      lineHeight: 1.4,
      fontWeight: "bold",
      color: "black",
    },
    h4: {
      fontSize: "1.25em",
      lineHeight: 1.4,
      fontWeight: "bold",
      color: "black",
    },
    body1: {
      fontSize: "1em",
    },
    body2: {
      fontSize: "0.875em",
    },
    label: {
      fontSize: "0.875em",
    },
    label2: {
      fontSize: "0.875em",
      color: "black",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1400,
    },
  },

  spacing: 5,
  palette: {
    background: {
      default: "#F6FAFF",
    },
    primary: {
      main: "#34404B",
      dark: "#595162",
    },
    white: "#fff",
    black: "#000",

    secondary: {
      main: "#de1f24",
      contrastText: "#fff",
    },

    tertiary: {
      main: "#377ae6",
      contrastText: "#fff",
    },
    gray: {
      main: "#bdbdbd",
      contrastText: "#fff",
    },
    error: {
      main: "#f00",
      contrastText: "#fff",
    },
    danger: {
      main: "#860E0E",
    },
    text: {
      primary: "#34404B",
      secondary: "#fff",
    },
    // error: will use the default color
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "& a": {
            color: "#C8AD55",
            textDecoration: "none",
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (min-width: 960px)": {
            paddingLeft: "50px !important",
            paddingRight: "50px !important",
          },
          "@media (max-width: 960px)": {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
          },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            outline: "none",
          },
          "&:focus": {
            outline: "none",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 19,
          textTransform: "none",
          fontWeight: "600",
          lineHeight: "1em",
        },
        contained: {
          ":hover": {
            backgroundColor: "#48535D",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#808080",
        },
        sizeSmall: {
          fontSize: "0.875rem",
        },
      },
    },

    // "& .MuiOutlinedInput-root": {
    //   "&:hover fieldset": {
    //     border: "1px solid #ddeff5 ",
    //     boxShadow: "0 0 6px 1px #cae6ef",
    //     borderWidth: "0.15rem",
    //   },
    //   "&.Mui-focused fieldset": {
    //     border: "1px solid #ddeff5 ",
    //     boxShadow: "0 0 6px 1px #cae6ef",
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "#ddeff5",
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#ddeff5",
              borderWidth: "0.15rem",
            },
          },
        },
      },
      defaultProps: {
        onWheel: (e) => {
          e.target.blur();
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "1px solid #ddeff5 ",
        },
        input: {
          backgroundColor: "#fff",
        },
        inputMultiline: {
          padding: 15,
        },
        inputSizeSmall: {
          fontSize: "0.875rem",
        },
        root: {
          padding: "0",
          borderRadius: 8,
          overflow: "hidden",
          borderColor: "#ddeff5",
          boxShadow: "none",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            borderColor: "#cae6ef",
            boxShadow: "0 0 6px 1px #cae6ef",
          },
        },
        adornedEnd: {
          paddingRight: 20,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          marginLeft: 10,
          marginRight: 10,
          fontSize: "1rem",
          "&.Mui-selected": {
            backgroundColor: "white",
            boxShadow: "0 0 2px 1px #dfedf2",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "0 0 2px 1px #dfedf2",
          ":hover": {
            // backgroundColor: "red",
          },
        },
        grouped: {
          ":not(:last-of-type)": {
            flexGrow: 1,
            borderRight: "1px solid #dfedf2",
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: "#34404B",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            opacity: 0.7,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 4,

          marginLeft: 4,
          color: "#34404B",
          "&.Mui-checked": {
            color: "#34404B",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: 10,
          alignItems: "flex-start",
          " .MuiCheckbox-root": {
            marginTop: -4,
            marginRight: 4,
          },
        },
        label: {
          fontSize: "0.85rem",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopRight: {
          marginTop: 120,
        },
      },
    },
  },
});
export default responsiveFontSizes(theme);
